<template>
  <div>
    <category-user-add
      :is-sidebar-active.sync="isSidebarActive"
      @refresh-data="() => { refCategoryUserList.refreshData() }"
    />
    <list
      ref="refCategoryUserList"
      sort-by="TicketCategoriaUsuarioId"
      refetch-records-name="usuarios"
      key-field="TicketCategoriaUsuarioId"
      :actions="actions"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchCategoryUsers"
      :filters.sync="filters"
      :delete-method="deleteCategoryUser"
    >
      <template #cell(nombreUsuario)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.nombreCompleto)"
              variant="light-danger"
            />
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.nombreCompleto }}
          </b-link>
          <small class="text-muted">@{{ data.item.nombreUsuario }}</small>
        </b-media>
      </template>
    </list>
  </div>
</template>

<script>
import {
  BMedia,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import i18n from '@/libs/i18n'
import helpDeskService from '@/services/helpDesk.service'
import List from '@/components/List.vue'
import router from '@/router'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import CategoryUserAdd from './CategoryUserAdd.vue'

export default {
  components: {
    List,
    CategoryUserAdd,
    BMedia,
    BAvatar,
    BLink,
  },
  setup() {
    const refCategoryUserList = ref(null)
    const isSidebarActive = ref(false)
    const { fetchCategoryUser, deleteCategoryUser } = helpDeskService()
    const tableColumns = [
      {
        key: 'ticketCategoriaUsuarioId',
        label: i18n.t('AppHelpDesk.id'),
        sortable: true,
      },
      {
        key: 'nombreUsuario',
        label: i18n.t('AppHelpDesk.categoryColumns.name'),
        sortable: false,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]
    const filters = ref([
      {
        type: 'select',
        name: 'usuarios',
        label: i18n.t('AppHelpDesk.filters.userName'),
        value: '',
        options: [],
        domainName: 'Empleado',
      },
    ])
    const actions = ref([
      {
        label: i18n.t('AppHelpDesk.addUser'),
        aclAction: 'create',
        aclResource: 'MesaAyuda',
        click: () => { isSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const tableActions = ref([
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'MesaAyuda',
        params: ['ticketCategoriaUsuarioId'],
        icon: 'TrashIcon',
      },
    ])
    const fetchCategoryUsers = (data, callback) => {
      fetchCategoryUser(router.currentRoute.params.ticketCategoriaId, data, callback)
    }
    return {
      refCategoryUserList,
      fetchCategoryUser,
      deleteCategoryUser,
      tableColumns,
      tableActions,
      filters,
      actions,
      isSidebarActive,
      fetchCategoryUsers,
      avatarText,
    }
  },
}
</script>
