<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    width="500"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ $t("AppUser.list.addNewUser") }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('AppHelpDesk.columns.user')"
            rules="required"
          >
            <b-form-group
              :label="$t('AppHelpDesk.columns.user')"
              label-for="usuario"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="usuario"
                v-model="ticketCategoriaUsuario.nombreUsuario"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="users"
                :reduce="(option) => option.userName"
                :disabled="!$can('write', 'MesaAyuda')"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-row
            v-if="$can('write', 'MesaAyuda')"
            class="float-right mt-2 mb-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Lists.Add") }}
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BRow,
  BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import optionService from '@/services/option.service'
import helpDeskService from '@/services/helpDesk.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import {
  required,
} from '@validations'
import { ref } from '@vue/composition-api'
import useradminService from '@/services/useradmin.service'

export default {
  components: {
    BSidebar,
    BForm,
    BRow,
    BFormGroup,
    BButton,
    BFormInvalidFeedback,
    vSelect,

    ValidationObserver,
    ValidationProvider,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const users = ref([])
    const ticketCategoriaUsuario = ref({
      ticketCategoriaId: 0,
      nombreUsuario: '',
    })
    const resetCategoryUserData = () => {
      ticketCategoriaUsuario.value = {
        ticketCategoriaId: 0,
        nombreUsuario: '',
      }
    }

    const {
      createCategoryUser,
    } = helpDeskService()
    const {
      fetchUsers,
    } = useradminService()

    fetchUsers({ limit: 50 }, data => {
      users.value = data.collection
    })

    const {
      refFormObserver,
      resetForm,
    } = formValidation(resetCategoryUserData)

    const onSubmit = () => {
      ticketCategoriaUsuario.value.ticketCategoriaId = router.currentRoute.params.ticketCategoriaId
      createCategoryUser(ticketCategoriaUsuario.value)
        .then(() => {
          emit('update:is-sidebar-active', false)
          emit('refresh-data')
        })
    }

    return {
      optionService,
      refFormObserver,
      resetForm,
      onSubmit,
      createCategoryUser,
      users,
      ticketCategoriaUsuario,
    }
  },
}
</script>
